export default function (network) {
  if (!network) {
    return ''
  }
  return (
    'https://stage-dia-cms.kliks.pl/logos/networks/' +
    network.toLowerCase().replace(/\s/g, '-') +
    '.png'
  )
}
